import { VASTClient, VASTParser, VASTTracker } from '@dailymotion/vast-client';
import videojs from 'video.js';
// import 'videojs-contrib-ads';

const Plugin = videojs.getPlugin('plugin');

const defaults = {
    seekEnabled: false,
    controlsEnabled: false,
    wrapperLimit: 10,
    withCredentials: true,
    skip: 0,
    vpaid: {
        containerId: undefined,
        containerClass: 'vjs-vpaid-container',
        videoInstance: 'none'
    }
};

/**
 * Create Source Objects
 *
 * @param {Array} mediaFiles  Array of media files
 * @return {Array} Array of source objects
 */
function createSourceObjects(mediaFiles) {
    return mediaFiles.map((mediaFile) => ({
        type: mediaFile.mimeType,
        src: mediaFile.fileURL
    }));
}

/**
 * Determine if the VAST creative has a VPAID media file
 *
 * @param {Object} vastCreative
 * @return {boolean}
 */
// function hasVPAID(vastCreative) {
//   const mediaFiles = vastCreative.mediaFiles;

//   for (let i = 0; i < mediaFiles.length; i++) {
//     if (mediaFiles[i].apiFramework && mediaFiles[i].apiFramework === 'VPAID') {
//       return true;
//     }
//   }
//   return false;
// }

class Vast extends Plugin {
    /**
     * Create a Vast plugin instance.
     *
     * @param  {Player} player
     *         A Video.js Player instance.
     *
     * @param  {Object} [options]
     */
    constructor(player, options) {
        // the parent class will add player under this.player
        super(player);

        this.options = videojs.mergeOptions(defaults, options);

        // Could be initialized already by user
        if (typeof player.ads === 'function') {
            player.ads(Object.assign({ debug: false }, options.ads || {}));
        }

        this.vastClient = new VASTClient();
        this.originalPlayerState = {};
        this.eventListeners = {};
        this.domElements = {};
        this.vastCreative = null;
        this.remainingCreative = null;
        this.isMidrollVideoPlayed = '';

        this._getVastContent()
            .then((res) => this._handleVast(res))
            .catch((err) => {
                this.player.trigger('adscanceled');
                // eslint-disable-next-line no-console
                console.log(`Ad cancelled: ${err.message}`);
                localStorage.removeItem("vastUrl");
                window.localStorage.setItem('vastUrl', []);
                localStorage.removeItem("vastDTO");
                window.localStorage.setItem('vastDTO', '')
                window.dispatchEvent(new Event('storage'))
            });

        this.player.ready(() => {
            this.player.addClass('vjs-vast');
        });
    }

    _handleVast(vast) {
        if (!vast.ads || vast.ads.length === 0) {
            this.player.trigger('adscanceled');
            localStorage.removeItem("vastUrl");
            window.localStorage.setItem('vastUrl', []);
            localStorage.removeItem("vastDTO");
            window.localStorage.setItem('vastDTO', JSON.stringify(vast));
            window.dispatchEvent(new Event('storage'))
            return;
        }

        const linearFn = (creative) => creative.type === 'linear';
        const companionFn = (creative) => creative.type === 'companion';

        const adWithLinear = vast.ads.find((ad) => ad.creatives.some(linearFn));

        const linearCreative = adWithLinear.creatives.find(linearFn);

        const companionCreative = adWithLinear.creatives.find(companionFn);

        const options = this.options;

        if (options.companion && companionCreative) {
            const variation = companionCreative.variations.find((v) =>
                v.width === String(options.companion.maxWidth) &&
                v.height === String(options.companion.maxHeight));

            if (variation) {
                if (variation.staticResource) {
                    if (variation.type.indexOf('image') === 0) {
                        const clickThroughUrl = variation.companionClickThroughURLTemplate;

                        const dest = window.document.getElementById(options.companion.elementId);

                        let html;

                        if (clickThroughUrl) {
                            html = `<a href="${clickThroughUrl}" target="_blank"><img src="${variation.staticResource}"/></a>`;
                        } else {
                            html = `<img src="${variation.staticResource}"/>`;
                        }
                        dest.innerHTML = html;
                    } else if (
                        [
                            'application/x-javascript',
                            'text/javascript',
                            'application/javascript'
                        ].indexOf(variation.type) > -1
                    ) {
                        // handle script
                    } else if (variation.type === 'application/x-shockwave-flash') {
                        // handle flash
                    }
                }
            }
        }

        this.tracker = new VASTTracker(
            this.vastClient,
            adWithLinear,
            linearCreative,
            companionCreative
        );

        this.vastCreative = linearCreative;

        localStorage.removeItem("vastDTO");
        window.localStorage.setItem('vastDTO', JSON.stringify(vast));
        if (linearCreative.mediaFiles.length) {
            // eslint-disable-next-line no-console
            let mediaURL = JSON.stringify(linearCreative.mediaFiles);
            localStorage.removeItem("vastUrl");
            window.localStorage.setItem('vastUrl', mediaURL);
            window.dispatchEvent(new Event('storage'))
            console.log('Trigger ads ready');
            this.player.trigger('adsready');
        } else {
            this.player.trigger('adscanceled');
        }
    }
    _getVastContent() {
        const { url, xml } = this.options;

        if (url) {
            return this.vastClient.get(url, {
                withCredentials: this.options.withCredentials,
                wrapperLimit: this.options.wrapperLimit
            });
        } else if (xml) {
            const vastParser = new VASTParser();

            let xmlDocument;

            if (xml.constructor === window.XMLDocument) {
                xmlDocument = xml;
            } else if (xml.constructor === String) {
                xmlDocument = new window.DOMParser().parseFromString(xml, 'text/xml');
            } else {
                throw new Error('xml config option must be a String or XMLDocument');
            }

            return vastParser.parseVAST(xmlDocument);
        }
        return Promise.reject(new Error('url or xml option not set'));
    }
}
// Define default values for the plugin's `state` object here.
Vast.defaultState = {};
// Register the plugin with video.js.
videojs.registerPlugin('vast', Vast);
export default Vast;
